<template>
  <div class="p-fluid function-view">
    <div class="p-col-12 p-lg-12">
      <div class="card card-w-title">
        <div class="row manager-title-row">
          <div class="p-col-4 manager-title-custom">{{ title }}</div>
          <div class="p-col-8 txt-right">
            <Toolbar class="fixed-bottom-toolbar bg-transparent border-0">
              <template slot="right">
                <Button
                  label="Quay lại"
                  @click="backToList()"
                  class="p-button-warning callback-btn"
                />
                <Button
                  v-if="mode == 'edit'"
                  :label="'Lưu'"
                  @click="triggerSave = true"
                  class="p-button-success main-style-button"
                />
                <Button
                  v-else
                  :label="'Thêm mới'"
                  @click="triggerSave = true"
                  class="p-button-success main-style-button"
                />
              </template>
            </Toolbar>
          </div>
        </div>
        <formEdit
          :key="componentKey"
          :mode="mode"
          @back="backToList"
          @save="saveData"
          :triggerSave.sync="triggerSave"
          :model="models"
          />
      </div>
    </div>
  </div>
</template>

<script>
import DataServices from '@/core/DataServices'
import formEdit from './add'
import gql from "graphql-tag";
export default {
  components: {
    formEdit
  },
  data() {
    return {
      categories: null,
      validationErrors: [],
      title: "Tạo thông báo",
      mode: 'add',
      models: {},
      componentKey: 0,
      lstStatus: [],
      status: 0,
      lstRequests: [],
      accountID: '',
      reload: false,
      triggerSave: false,
    }
  },
  async mounted () {
    this.mode = this.$route.name === 'editNotification' ? 'edit' : this.mode;
    this.title = this.mode === 'edit' ? 'Edit notification' : this.title;
    if (this.$route.params.id !== undefined) await this.getData(this.$route.params.id);
    if (!this.$commonFuction.isEmpty(this.$route.query)){
      if (!this.$commonFuction.isEmpty(this.$route.query.page_transaction)){
        this.page_transaction = this.$route.query.page_transaction;
      }
    }
    // await this.userLogin();
    //check quyền
    var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_NOTIFICATION);
    if (this.mode == 'edit'){
      if (arr_role.indexOf(this.$constants.ROLE.EDIT) < 0){
        this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
      }
    }else {
      if (arr_role.indexOf(this.$constants.ROLE.ADD) < 0){
        this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
      }
    }
  },
  methods: {
    backToList() {
      this.$router.push({ path: '/admin/list-notification' });
    },
    async saveData(params) {
      var that = this;
      if (params !== undefined && Object.entries(params).length > 0) {
        var objects = {
          'is_push': true,
          'action_key' : 'NOTIFICATION',
          'is_admin': true,
          'multi_uid': params.multi_uid.length > 0 ? params.multi_uid : null
        }
        if (this.mode === 'add') {
          objects.type =  params.type.key;
          objects.type_id =  params.type.value;
          var notification_lang = [
            {short_content:params.short_content_en,title: params.title_en,content: params.content_en, lang :'vi'},
            // {short_content:params.short_content,title: params.title,content: params.content, lang :'da'},
          ]
          var dataInsert = this.$commonFuction.convertJsonObject(objects);
          dataInsert.notification_lang = {data: this.$commonFuction.convertJsonObject(notification_lang)}
          await this.$apollo.mutate({
            mutation: DataServices.insertData('notification'),
            variables: {
              'objects': dataInsert
            }
          }).then(async (res) => {
            console.log(res);
            if (res.data[Object.keys(res.data)].affected_rows) {
              var returning = res.data[Object.keys(res.data)].returning[0];
              var link_webview = that.$ConfigEvn.base_url_api+'/webview/notification/'+returning.id;
              var queryUpdateNoti = `
                mutation update_notification_multil($notification_object: notification_set_input) {
                  update_notification(where: { id: {_eq: "${returning.id}"}}, _set: $notification_object) {
                    affected_rows
                    returning {
                      id
                    }
                  }
                }
              `;
              that.$apollo.mutate({
                mutation: gql(queryUpdateNoti),
                variables: {notification_object: {webview_url :link_webview}}
              })
              that.redirectEdit();
            }
          }).catch(async error => {
            console.log('error', error);
            that.$toast.add({
              severity: 'error',
              summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
              detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
              life: 3000
            });
            this.$commonFuction.is_loading(false);
          })
        }else if(this.mode === 'edit'){
          var notification_id = this.$route.params.id;
          var notification_lang_objects = [
            {notification_id:notification_id,short_content:params.short_content_en,title: params.title_en,content: params.content_en, lang :'en'},
            {notification_id:notification_id,short_content:params.short_content,title: params.title,content: params.content, lang :'da'},
          ]
          var queryUpdate = `
            mutation update_users_multil($notification_lang_objects: [notification_lang_insert_input!]!) {
              delete_notification_lang(where: { notification_id: {_eq: "${notification_id}"}}) {
                returning {
                  id
                }
              }
              insert_notification_lang(
                objects: $notification_lang_objects
              ) {
                returning {
                  id
                }
              }
            }
          `;
          let variables = {
            notification_lang_objects: notification_lang_objects,
          };
          this.$apollo.mutate({
            mutation: gql(queryUpdate),
            variables: variables
          }).then(() => {
            that.redirectEdit()
          }).catch(async error => {
            console.log('error', error);
            that.$toast.add({
              severity: 'error',
              summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
              detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
              life: 3000
            });
            that.$commonFuction.is_loading(false);
            // this.summitEvent = false;
          });
        }
      }
    },
    async redirectEdit() {
      var that = this;
      var router_back = {path: '/admin/list-notification'};
      that.$commonFuction.is_loading(false);
      if(that.mode == 'add'){
        that.$router.push(router_back);
      }else{
        if (!that.$commonFuction.isEmpty(router_back)){
          // that.page_transaction = ""+that.page_transaction
          router_back.query = { page_transaction: that.page_transaction.toString() };
        }
        that.$router.push(router_back);
      }
      that.$toast.add({ severity: 'success', summary: 'Success', detail: 'Save data successfully', life: 3000 })
    },
    async redirectAction() {
      var that = this;
      that.$commonFuction.is_loading(false);
      var router_back = {path: '/admin/list-notification'};
      that.$router.push(router_back);
      that.$toast.add({ severity: 'success', summary: 'Thành công', detail: 'Lưu dữ liệu thành công', life: 3000 })
    },
    async getData(id) {
      var {data} = await this.$apollo.query({
        query: DataServices.getList('notification', {"fields" : "id type_id active  notification_lang{lang title content short_content}"}),
        variables: {
          where_key: {
            id: {'_eq': id}
          }
        },
        fetchPolicy: "network-only"
      })
      var model = data[Object.keys(data)];
      if (model.length > 0) {
        this.models = model[0];
      }
      this.componentKey++;
    },
    async userLogin() {
      var user = this.$store.getters.user;
      var {data} = await this.$apollo.query({
        query: DataServices.getList('accounts', {"fields" : "uid"}),
        variables: {
          where_key: {
            id: {'_eq': user}
          }
        },
        fetchPolicy: "network-only"
      });
      if (data && data[Object.keys(data)] !== undefined && data[Object.keys(data)].length > 0) {
        this.accountID = data[Object.keys(data)][0].uid;
      }
    }
  }
}
</script>

<style lang="scss">
  .manager-title-custom{
    color: #1D3118;
    font-size: 18px;
    /* margin-bottom: 16px; */
    padding-bottom: 10px;
    /* border-bottom: 1px solid #EEEEEE; */
    font-weight: 700;
  }
.p-inputnumber-buttons-stacked {
  .p-inputnumber-button-group {
    .p-button.p-button-icon-only { padding: 0 !important; }
  }
}
.note{ letter-spacing: 0px; color: #707070; margin-bottom: 24px; padding-top: 10px;}
.p-formgrid { margin: 0;
  .p-panel-header {color: #000000; font-size: 18px; font-weight: bold; background: #EEEEEE;}
  .p-card-field{ padding: 24px;
    .p-field{
      .p-col-fixed { width: 300px; display: inline-block !important; margin: 0;}
      .p-col-width{width:500px !important; border-radius: 5px;
        &.disabled{background: #EEEEEE; border: 0 none; padding: 8px 10px;}
        .file-select{margin: 0;
          input[type="file"]{display: none;}
          .select-button{display: inline-block; cursor: pointer; background: transparent; border-radius: 5px;  color: #000000; font-size: 13px; padding: 3px 16px;  border: 1px solid #B4B4B4;}
        }
        &.pac-target-input{ height: 80px;  word-break: break-all; overflow-wrap: break-word;  resize: none;}
      }
      .box-images{ margin: 0; margin-top: 10px; position: relative;
        .item-img{width: 150px; height: 150px;  margin-right: 8px; margin-bottom: 8px; display: inline-flex; position: relative;
          img{max-width: 100%; height: auto;margin: 0 auto; object-fit: contain; padding: 5px;}
          .button-clear{border-radius: 10px; width: 24px; height: 24px; background: #F5564E; float: right; cursor: pointer; position: absolute; right: 0px;color: #ffffff;
            img{vertical-align: top;}
          }
        }
      }
      .p-invalid{display: block;}
    }
  }
  .fixed-bottom-toolbar{background: transparent; border: 0 none; margin-top: 22px; border-top: 1px solid #B4B4B4; padding: 0; padding-top: 30px;
    .p-button{width: auto; margin-right: 8px;
      &:last-child{margin-right: 0;}
    }
  }
}
.manager-title-row{
  border-bottom: 1px solid #EEEEEE;
}
.function-view .p-toolbar-group-right {
  flex-basis: 30%;
  justify-content: flex-end;
}
.function-view .p-button-warning.p-button {
  margin: 0 10px;
}
</style>
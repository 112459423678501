<template>
  <form id="frm_action">
    <div class="p-formgrid">
      <div class="p-card-field p-grid">
        <div class="p-field p-col-12">
          <label class="p-col-fixed txt-left">Tiêu đề <span class="span-cus">*</span></label>
          <div class="p-col">
            <InputText v-model="title_en" placeholder="Tiêu đề"  id="title_en" type="text" />
          </div>
        </div>
        <div class="p-field p-col-12">
          <label class="p-col-fixed txt-left">Đối tượng <span class="span-cus">*</span></label>
          <div class="p-col">
            <Dropdown :disabled="mode == 'edit'" v-model="types" :options="receiver" optionLabel="label" />
          </div>
        </div>
         <!--  -->
        <div class="p-field p-col-12" v-if="types.key == 'USER'">
            <label class="p-col-fixed txt-left">Người dùng <span class="span-cus">*</span></label>
            <div class="p-col">
                <v-select :clearable="false" multiple title="Người dùng" id= "a1" :filterable="false" placeholder="Chọn người dùng" v-model="selUser" :options="listUser" label="name" @search="fetchOptionsUser" class="p-inputtext p-component p-filled">
                    <span slot="no-options" @click="$refs.select.open = false">
                        Không có dữ liệu
                    </span>
                </v-select>
                <input class="input_tmp_validator" id="typeservice_id">
            </div>
        </div>
        <div class="p-field p-col-12">
          <label class="p-col-fixed txt-left">Nội dung ngắn <span class="span-cus">*</span></label>
          <div class="p-col">
            <InputText v-model="short_content_en" type="text" id="short_content_en" placeholder="Nôi dung ngắn"/>
          </div>
        </div>
        <div class="p-field p-col-12">
          <label class="p-col-fixed txt-left">Nội dung <span class="span-cus">*</span></label>
          <div class="p-col">
            <input class="input_tmp_validator" id="validator_content_en">
            <CkditorCore :contentSet="this.content_en" @newdata="handleDataCkditor($event,'content_en')"></CkditorCore>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import DataServices from '@/core/DataServices';
import CkditorCore from '../CkditorCore';
export default {
  components: {
    CkditorCore
  },
  props: {
    mode: String,
    model: Object,
    categories: Array,
    requests: Array,
    triggerSave: Boolean
  },
  watch: {
    'triggerSave'() {
        if (this.triggerSave) {
          this.saveData();
      }
    },
  },
  data() {
    return {
      trigger_save: this.triggerSave,
      teststsss: '',
      title_en: '',
      title: '',
      content: '',
      content_en: '',
      users: [],
      lstUsers: [],
      lstAccounts: {},
      types: { value: 1, label: 'Tất cả người dùng', key : 'ALL'},
      receiver: [{ value: 1, label: 'Tất cả người dùng', key : 'ALL'}, {value: 3, label: 'Người dùng', key : 'USER'}],
      validationErrors: {},
      short_content: '',
      short_content_en: '',
      selUser: [],
      listUser: [],
      multi_uid: []
    }
  },
  mounted () {
  //  this.getUsers();
   if (this.model !== undefined && Object.keys(this.model).length > 0) {
      console.log("this.model",this.model)
      if(this.model.notification_lang.length > 0){
        for (let i = 0; i < this.model.notification_lang.length; i++) {
          const e = this.model.notification_lang[i];
          if(e.lang == 'en'){
            this.title_en = e.title;
            this.short_content_en = e.short_content;
            this.content_en = e.content;
          }else if(e.lang == 'da'){
            this.title = e.title;
            this.short_content = e.short_content;
            this.content = e.content;
          }
        }
      }
      if(this.model.type_id == 1){
        this.types = { value: 1, label: 'Tất cả người dùng', key : 'ALL'}
      }else if(this.model.type_id == 2){
        this.types = {value: 2, label: 'Clubs', key : 'CLUB'}
      }else if(this.model.type_id == 3){
        this.types = {value: 3, label: 'Người dùng', key : 'USER'}
      }
    }
  },
  async created() {
    await this.getListUser();
  },
  methods: {
    handleDataCkditor: function(e,key) {
      var dataContent = (e.length) ? e[0]:'';
      if (key == 'content'){
        this.content = dataContent;
      }else if (key == 'content_en'){
        this.content_en = dataContent;
      }
    }
    ,
    async getListUser(){

      var where_key = {
        deleted : {_eq : false},
        active: {_eq: true},
        type: {_eq: 'user'}
      }
      let {data} = await this.$apollo.query({
        query: DataServices.getList('users',{"fields" : `id first_name last_name email gender phone prefix_phone full_birthday patient_code`}),
        variables: {
          where_key: where_key,
          orderBy:[{created_at : 'desc'}]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      var list_user = [];
      // console.log("list===",list);
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        list_user.push({id : e.id, name :e.first_name + ' ' + e.last_name, first_name :e.first_name, last_name: e.last_name, email: e.email, gender: e.gender, phone: e.phone, prefix_phone: e.prefix_phone, full_birthday: e.full_birthday, patient_code: e.patient_code});
      }
      this.listUser = list_user;
      this.listUserAll = list_user;
    },
    async fetchOptionsUser(search, loading){
      console.log(loading);
      console.log('search',search);
      if(search !== '' && search !== null){
        var where_key = {
          // plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
          deleted : {_eq : false},
          active: {_eq: true},
          type: {_eq: 'user'},
          _or: {
            first_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
            last_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
          }
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('users',{"fields" : `id first_name last_name email gender phone prefix_phone full_birthday patient_code`}),
          variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_user = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_user.push({id : e.id, name :e.first_name + ' ' + e.last_name, first_name :e.first_name, last_name: e.last_name, email: e.email, gender: e.gender, phone: e.phone, prefix_phone: e.prefix_phone, full_birthday: e.full_birthday, patient_code: e.patient_code});
        }
        this.listUser = list_user;
      }else{
        // cosnole.log("dasdasd")
        this.listUser = this.listUserAll
      }
    },
    async validateData() {
      this.validationErrors = {};
      // if (this.$commonFuction.isEmpty(this.title)) {
      //   document.getElementById("title").setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
      // } else {
      //   document.getElementById("title").setCustomValidity("");
      // }
      if (this.$commonFuction.isEmpty(this.title_en)) {
        document.getElementById("title_en").setCustomValidity("Tiêu đề không được bỏ trống!");
      } else {
        document.getElementById("title_en").setCustomValidity("");
      }
      var typeservice_id = document.getElementById("typeservice_id");
      if (this.types.key == 'USER') {
        if (this.$commonFuction.isEmpty(this.selUser) || this.selUser.length <= 0) {
          typeservice_id.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
        } else {
          typeservice_id.setCustomValidity("");
        }
      // }else {
      //   typeservice_id.setCustomValidity("");
      }
      if (this.$commonFuction.isEmpty(this.short_content_en)) {
        document.getElementById("short_content_en").setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
      } else {
        document.getElementById("short_content_en").setCustomValidity("");
      }
      // if (this.$commonFuction.isEmpty(this.content)) {
      //   document.getElementById("validator_content").setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
      // } else {
      //   document.getElementById("validator_content").setCustomValidity("");
      // }
      if (this.$commonFuction.isEmpty(this.content_en)) {
        document.getElementById("validator_content_en").setCustomValidity("Nội dung không được bỏ trống!");
      } else {
        document.getElementById("validator_content_en").setCustomValidity("");
      }

      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()){
        console.log('test=====================');
        inpObj.reportValidity();
        return true;
      }
    },
    backToList() {
      this.$emit('back');
    },
    async saveData() {
      this.$commonFuction.is_loading(true);
      // this.$commonFuction.is_loading(true);
      this.$emit('update:triggerSave', false);
      if (await this.validateData()) {
        // this.saving = false;
        this.$commonFuction.is_loading(false);
        return
      }

      if (Object.entries(this.validationErrors).length === 0) {
        var multi_uid = [];
        console.log(this.selUser);
        for(let i =0 ; i < this.selUser.length; i++) {
          multi_uid.push(this.selUser[i].id);
        }
        var params = {
          title: this.title,
          title_en: this.title_en,
          content: this.content,
          type: this.types,
          content_en : this.content_en,
          short_content: this.short_content,
          short_content_en: this.short_content_en,
          multi_uid: multi_uid
        }
        console.log("params",params)
        this.$emit('save', params);
      }
    },
    onChange(event) {
      this.category_id = event.value.id;
    },
    onFileClear(index) {
      if (this.images !== undefined && this.images.length > 0) {
        this.images.splice(index, 1);
      }
    },
    isImage(file) {
      return /^image\//.test(file.type);
    },
    async onFileSelect(event) {
      let files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
      if(files.length > 0) {
        if (files.length + this.images.length > this.maxFiles) {
          alert("Hình ảnh quá số lượng tối đa là " + this.maxFiles + " hình");
        } else {
          var arrTemp = [], arrFiles = [];
          for (let file of files) {
            if (this.isImage(file) && file.size <= this.maxFileSize) {
              file.source = file;
              file.objectURL = window.URL.createObjectURL(file);
              arrTemp.push(file);
            }
            if (file.size > this.maxFileSize) arrFiles.push(file);
          }
          if (arrFiles.length > 0) {
            alert("Hình ảnh của bạn đã quá dung lượng 1MB");
          } else {
            this.images = this.images.concat.apply(this.images, arrTemp);
          }
        }
      }
    },
    async getUsers() {
      var {data} = await this.$apollo.query({
        query: DataServices.getList('users'),
        variables: {
          where_key: {
            deleted: {_eq: false},
            is_admin : {_eq: false},
            accounts : {
              deleted: {_eq: false},
            },
          }
        },
        fetchPolicy: "network-only"
      })
      var model = data[Object.keys(data)];
      if (model.length > 0) {
        for (let index = 0; index < model.length; index++) {
          const user = model[index];
          let label = ''
          // if(user.code && user.is_client == true){
          //   label = user.code
          // }
          if(user.fullname){
            label = user.fullname
          }
          if(label !== ''){
            label = label + '-' +user.phone
          }else{
            label = user.phone
          }
          // if(user.code_caregiver && user.is_update_caregiver == true){
          //   label = label + ' ' + user.code_caregiver
          // }
          this.users.push({label: label, code : user.code, id: user.id, fullname: user.fullname});
        }
      }
      this.lstUsers = this.users;
    }
  }
}
</script>
<style lang="scss">
.p-inputnumber-buttons-stacked {
  .p-inputnumber-button-group {
    .p-button.p-button-icon-only { padding: 0 !important; }
  }
}
.view-action{
  .toolbar-top{background: #fff; padding: 0; border: 0 none;margin-bottom: 16px; padding-bottom: 10px; border-bottom: 1px solid #EEEEEE;
    .manager-title{border: 0 none; padding: 0; margin: 0;}
    .p-toolbar-group-right {
      .p-button{margin-right: 8px; width: auto;
        &:last-child{margin-right: 0;}
      }
    }
  }
  .p-tabview{
    .p-tabview-panels{ padding: 0;}
    .p-tabview-nav{
      li{margin-right: 8px;
        .p-tabview-nav-link{border: 1px solid #B4B4B4; border-radius: 5px 5px 0px 0px !important; background: #eee; font-size: 15px;
          &:hover{text-decoration: none;}
        }
        &.p-highlight{
          .p-tabview-nav-link{background: #00A8FF !important;border: 1px solid #B4B4B4; border-radius: 5px;}
        }
        &:hover{
          .p-tabview-nav-link{background: #00A8FF !important; color: #fff !important;}
        }
      }
    }
  }
  .p-formgrid { margin: 0;
    .p-panel-header {color: #000000; font-size: 18px; font-weight: bold; background: #EEEEEE;}
    .p-card-field{ padding: 24px;
      .p-field{
        .p-col-fixed { width: 300px; display: inline-block !important; margin: 0;}
        .p-inputtext p-component p-filled{width:500px !important; border-radius: 5px;
          &.disabled{background: #EEEEEE; border: 0 none; padding: 8px 10px; color: #707070; height: 40px;}
          &.rows-4{height: 80px;}
          .file-select{margin: 0;
            input[type="file"]{display: none;}
            .select-button{display: inline-block; cursor: pointer; background: transparent; border-radius: 5px;  color: #000000; font-size: 13px; padding: 3px 16px;  border: 1px solid #B4B4B4;}
          }
        }
        .box-images{ margin-top: 10px; position: relative;
          .item-img{height: 150px;  margin-right: 8px; margin-bottom: 8px; display: inline-flex; position: relative; border: 0.5px solid #ccc;
            &.p-col-5{width: 18%;;}
            img{max-width: 100%; height: auto;margin: 0 auto; object-fit: contain;}
            .button-clear{width: 18px; height: 18px; background: #F5564E; float: right; cursor: pointer; position: absolute; right: 0px;
              img{vertical-align: top;}
            }
          }
        }
      }
    }
  }
  .p-rates{
    .top-rates{ padding: 16px;color: #000;border-bottom: 1px solid #B4B4B4; 
      label{margin: 0;}
      span{font-weight: 600; padding-left: 100px;}
    }
    .list-rates{padding: 16px;}
  }
  .table-view >.p-datatable .p-datatable-tbody > tr > td {
    color: #707070 !important;
  }
  .p-inputtext {
    min-height: 40px;
  }
  .p-formgrid .p-card-field .p-field .p-col-width {
    min-height: 40px;
    width: 100% !important;
  }
  .vs__dropdown-option.vs__dropdown-option--selected {
    background-color: #142053 !important;
    color: #ffffff;
  }
}

.span-cus{
  color : red !important;
}

label.text-active {
  margin: 0 1rem;
}
</style>